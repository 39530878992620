import smsConfirmationInputTemplate from "../templates/smsConfirmationInput.html";
//import smsConfirmationTemplate from "../templates/smsConfirmation.html";GlorySoft_036_t

//function smsConfirmationDirective() {GlorySoft_036_t пересечение с модулем смс-авторизации
//    return {
//        restrict: 'A',
//        scope: {
//            confirmationPhone: '<?',
//            pageName: '<?'            
//        },
//        controller: 'SmsConfirmationCtrl',
//        controllerAs: 'smsConfirmation',
//        templateUrl: smsConfirmationTemplate,
//        bindToController: true,
//    };
//}

const smsConfirmationInputComponent = {
  controller: 'SmsConfirmationInputCtrl',
  templateUrl: smsConfirmationInputTemplate,
  bindings: {
    count: '<',
    disabled: '<?',
    focusOnStart: '<?',
    onComplete: '&',
    onInit: '&'
  }
};
export {
//smsConfirmationDirective,GlorySoft_036_t
smsConfirmationInputComponent };