import './smsConfirmation.scss';
import SmsAuthCtrl from './controllers/smsAuthCtrl.js';
//import SmsConfirmationCtrl from './controllers/smsConfirmationCtrl.js';GlorySoft_036_t
import SmsConfirmationInputCtrl from './controllers/smsConfirmationInputCtrl.js';
//import smsConfirmationService from './services/smsService.js';GlorySoft_036_t
import { /*GlorySoft_036_t smsConfirmationDirective, */smsConfirmationInputComponent } from './directives/smsDirectives.js';
const moduleName = 'smsConfirmation';
angular.module(moduleName, [])
//.service('smsConfirmationService', smsConfirmationService)GlorySoft_036_t
.controller('SmsAuthCtrl', SmsAuthCtrl)
//.controller('SmsConfirmationCtrl', SmsConfirmationCtrl)GlorySoft_036_t
.controller('SmsConfirmationInputCtrl', SmsConfirmationInputCtrl)
//.directive('smsConfirmation', smsConfirmationDirective)GlorySoft_036_t
.component('smsConfirmationInput', smsConfirmationInputComponent);
export default moduleName;